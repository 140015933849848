<template>
     <div>
      <h1>{{ $t('help.ISACloseFollowUpLicense_title') }}</h1>
      <h3>{{ $t('Your license level does not allow you to activate this feature.') }}</h3>
      <br/>
      <p class="text-justify">
          {{ $t('This features allows you to activate Bed Exit detection and be alerted when a patient leaves its bed. Contact your MintT Customer Success Manager or MintT support to subscribe to this feature (<b>support@mintt.care</b>).') }}
      </p>
      
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
