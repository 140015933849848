<template>
     <div>
      <h1>{{ $t('help.ISAOutOfLicenseFall_title') }}</h1>            
      <br/>
      <p class="text-justify">
          {{ $t('You are already using {af} of your {tf} fall detection licences.', { af: licencedFallDetection, tf: allowedFallDetection }) }}
      </p>
      <p class="text-justify">
          {{ $t('To obtain more licenses: click on the following button to be contacted by your MintT Customer Satisfaction Manager ') }}
      </p>      
      <div class="text-center">
        <button type="button" class="btn btn-primary" @click="contactme">Contact Me</button>
    </div>  
    </div>
</template>

<script>
import { SPService } from '@/services/sp.service.js'


export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {
    contactme() {
        SPService.contactMe(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {
          organizationLabel: this.$store.state.fleet.currentOrganization,
          date: new Date(),
          email: this.$store.state.user.currentUser.email || this.$store.state.user.currentUser.username,          
        });
        this.$store.commit('user/NEEDS_HELP',null);   

        const self = this;
        setTimeout(() => {
          self.$bvToast.toast(this.$t('You will be contacted soon by your MintT Customer Satisfaction Manager'), {
              title: this.$t('Customer Satisfaction Manager'),            
              solid: true,
              variant: 'success',
              duration: 5000
        });
        }, 1000)                   
    }
  },
  mounted() {
  },
  computed: {
    licencedKiosks() {
      return this.$store.state.fleet.users.filter( u =>u.organizationLabel === this.$store.state.fleet.currentOrganization && u.role === 0 && !u.deleted).length
    }, 
    licencedFallDetection() {
      let total = 0;
      for(let rs of this.$store.state.fleet.roomsetList.filter(tt => tt.organizationLabel === this.$store.state.fleet.currentOrganization && tt.label.charAt(0) !== '_')) {
        total += rs.roomList.filter(r => r.sensor.settings.enabled).length
      }
      return total;      
    },
    licencedBedExitDetection() {
      let total = 0;
      for(let rs of this.$store.state.fleet.roomsetList.filter(tt => tt.organizationLabel === this.$store.state.fleet.currentOrganization && tt.label.charAt(0) !== '_')) {
        total += rs.roomList.filter(r => r.sensor.settings.enableBedExitDetection).length
      }
      return total;      
    },
    allowedKiosks() {
      const roomset = this.$store.state.fleet.roomsetList.filter(rs => rs.organizationLabel === this.$store.state.fleet.currentOrganization && rs.label.charAt(0) !== '_')[0];
      return roomset.licensing.activeKioskAllowed;            
    }, 
    allowedFallDetection() {
      const roomset = this.$store.state.fleet.roomsetList.filter(rs => rs.organizationLabel === this.$store.state.fleet.currentOrganization && rs.label.charAt(0) !== '_')[0];
      return roomset.licensing.activeFallAllowed;    
    },
    allowedBedExitDetection() {
      const roomset = this.$store.state.fleet.roomsetList.filter(rs => rs.organizationLabel === this.$store.state.fleet.currentOrganization && rs.label.charAt(0) !== '_')[0];
      return roomset.licensing.activeBedExitAllowed; 
    },
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
