<template>
     <div>
      <h1>{{ $t('help.ISAStatreponseTime_title') }}</h1>
      <p class="text-justify">
        {{ $t('The average, median and standard deviation of the call response time. The call response time is the time between the moment the phone start ringing and the phone call is taken.') }}
      </p>
      
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
