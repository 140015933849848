<template>
  <b-modal 
    hide-footer v-model="showLoginModal" 
    :title="$t('Please authenticate')"
    @hidden="close">
    <ISALogin @loginSuccess="loginSuccess" :modal="true" />
  </b-modal>
</template>

<script>
import { AuthService } from "@/services/auth.service";
import { SPService } from "@/services/sp.service";
import ISALogin from "@/components/ISA/session/ISALogin";

export default {
  components: {
    ISALogin,
  },
  data() {
    return {
      showLoginModal: false,
    };
  },
  watch: {
    "$store.state.user.needsLogin": function (val) {
      this.showLoginModal = val;
    },
    "$store.state.user.needsKioskLogin": function (val) {
      this.showLoginModal = val;
    },
    showLoginModal: function (val) {
      if (!val) {
        this.$store.dispatch("user/needsLogin", false);
        this.$store.dispatch("user/needsKioskLogin", false);
        SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'show_login_modal',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
      }
    },
  },
  methods: {
    close() {           
        this.$store.dispatch("user/needsLogin", false);
        this.$store.dispatch("user/needsKioskLogin", false); 
    },
    loginSuccess() {
      if (
        this.$store.state.user.needsLogin &&
        this.$store.state.user.needsLoginData
      )
      {        
        if(this.$store.state.user.needsLoginData.action)
          this.$store.dispatch(
            this.$store.state.user.needsLoginData.action,
            this.$store.state.user.needsLoginData.args
          );
      }

      if (this.$store.state.user.needsKioskLogin) {
        if (this.$router.currentRoute.path !== "/app/kiosk")
          this.$router.push("/app/kiosk");
      }
      this.$store.dispatch("user/needsLogin", false);
      this.$store.dispatch("user/needsKioskLogin", false);
    },
  },
  mounted() {
    this.showLoginModal = false;
    this.$store.dispatch("user/needsLogin", false);
    this.$store.dispatch("user/needsKioskLogin", false);
  },
  name: "ISALoginModal",
};
</script>

<style lang="scss" scoped>
</style>
