<template>
  <b-modal
      hide-footer
      v-model="showSavePattern"
      :title="$t('Save your lock pattern on this device')"
      size="lg"      
      class=""
      >        
          
      <div class="d-flex justify-content-center">          
          <img :src="IMGService.isalogo" class="brand_logo" alt="Logo" />          
              
          <div class="justify-content-center form_container" >
            <form style="margin-top: 20px; text-align: center;"  >
                    <div class="user-thumb text-center m-b-30">
                      <img
                        :src="IMGService.isalock"
                        class="rounded-circle img-thumbnail"
                        alt="thumbnail"
                        style="height: 80px; padding-top:-10px"
                        v-b-toggle.collapseAdvanced
                      />
                      <div class="form-group text-center row m-t-20" style="margin-top: 50px">                    
                                <div class="row">
                                    <div class="col-centered" >
                                        <span class="text-justify" style="font-weight: bold; white-space: pre-line">{{text}}</span>                                          
                                    </div>
                              </div>                    
                      </div>
                    </div>


                      <LockPattern @pattern="onPattern" ref='lp'
                          :cerror="patternError"
                          :csuccess="patternSuccess"
                          v-if="!readyToSave"
                      />

                      <div class="form-group text-center" >                    
                        <b-button v-if="readyToSave" variant="primary" @click="savePattern">{{ $t('Save my new pattern')}} </b-button>                          
                      </div>
                      <div class="form-group text-center" >                    
                        <b-button  variant="secondary" @click="close">{{ $t('Cancel')}} </b-button>                          
                      </div>
            </form>
          </div>
      </div>      

      
    </b-modal>  
</template>

<script>
import { AuthService } from "@/services/auth.service";
import { SPService } from "@/services/sp.service";
import LockPattern from "@/components/ISA/session/LockPattern";
import { API_URL } from '../../../.env'
import { IMGService } from '@/services/img.service'
export default {
  name: "isa-loginform",
  props: {
    method: { type: Function }
  },
  components: {
    LockPattern
  },
  data() {
    return {
      IMGService,
      passwordChecked: true,     
      isLoading: false,
      patternSuccess: false,
      patternError: false,
      firstPattern: false,
      firstPatternData: null,
      readyToSave: false,
      text: this.$t("Enter a lock pattern to quickly signup next time."),
      showSavePattern: false,
      hidePatten: false,
    };
  },
  watch: {
    '$store.state.user.needsPatternSave' : function(val) {   
         
        if(val === false)
          this.showSavePattern = false
        else 
        {
          this.showSavePattern = val != null || val === true
          SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'propose_save_pattern',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
        }
        this.text= this.$t("Enter a lock pattern to quickly signup next time."),
        this.firstPattern = false;
        this.firstPatternData = null;
        this.readyToSave = false;
    }
  },
  created() {

  },
  destroyed() {
    if (this.$refs && this.$refs.touchArea) {
      window.removeEventListener("mousedown", this.touchstartANDmousedown);
      window.removeEventListener("touchdown", this.touchstartANDmousedown);
      window.removeEventListener("mouseup", this.touchendANDmouseup);
      window.removeEventListener("touchend", this.touchendANDmouseup);
      window.removeEventListener("touchmove", this.mousemoveANDtouchmove);
      window.removeEventListener("mousemove", this.mousemoveANDtouchmove);
    }
  },
  computed: {
  },
  methods: {
    async onPattern(pattern) {
      console.log(pattern);
      if(!this.firstPattern) {
        this.firstPattern = true;        
        this.firstPatternData = pattern;
        console.log("FIRST PATTERN: " + pattern)
        this.patternSuccess = true
        this.text = this.$t("Please enter you pattern again, just to be sure")        
      } else
      {
        console.log("FIRST PATTERN: " + this.firstPatternData)
        console.log("SECOND PATTERN: " + pattern)
        if(pattern === this.firstPatternData)
        { // success, we can save this patter          
          this.patternSuccess = true;
          this.text = this.$t("This is correct, your lock pattern is ready.");
          this.readyToSave = true;
          this.readyToSaveData = pattern;
          /*
          window.setTimeout(function() {
              this.patternSuccess = false;
            }, 35000);
            */
          
            
        } // different pattern, let's restart
        else
        {
          this.patternError = true;
          this.firstPattern = false
          this.firstPatternData = null
          this.text = "Patterns does not match. Please try again."     
          this.hidePatten = true;
          setTimeout(() => {
              this.text = "Please define your new lock pattern."     
              this.patternError = false;                              
              this.hidePatten = false;
          }, 2000);
        }
        
        
      }
    },
    savePattern() {
      this.$store.dispatch('user/savePattern', { user: this.$store.state.user.currentUser, apiUrl: API_URL.url,  secret: this.readyToSaveData, password: this.$store.state.user.needsPatternSavePwd })      
      SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                                action: 'save_pattern',
                                path: window.location.pathname,
                                data: window.location.href,
                            });
    },
    close() {
      this.showLoginModal = false
      this.$store.commit('user/SET_NEEDS_LOCKPATTERN', { password: null, enable: false }  )
    }
  },
  mounted() {
    this.showLoginModal = false
    this.$store.commit('user/SET_NEEDS_LOCKPATTERN', { password: null, enable: false }  )
  },
};
</script>

<style lang="scss" scoped>
.isa-primary {
  background-color:#36958d !important;
}

.user_card {
  height: 400px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: auto;
  background: white;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.brand_logo {
  position: absolute;
  text-align: center;
  height: 55px;
}
.form_container {
  margin-top: 100px;
}
.login_btn {
  width: 100%;
  background: #36958d !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: #36958d !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #c0392b !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

.btn:focus {
  outline: none !important;
}

.btn-secondary {
  border-color: white;
}

.nav-pills {
    background-color: white;
}

.text-12 {
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.text-muted {
  font-weight: bold;
  color: rgb(87, 80, 80) !important;
}

.signinbtn {
  color:  rgb(0, 0, 0);
  font-weight: bold;
}

@media screen and (max-width: 640px) {
    .text-muted {
        color: rgb(129, 122, 122) !important;
    }
}
</style>
