<template>
     <div>
      <h1>{{ $t('help.ISATimeZoneHelp_title') }}</h1>
      <p class="text-justify">
          {{ $t('Select the timezone of the geographic location of the sensors. This timezone is used for scheduled phone calls and scheduled activation of bed exits hours. Those hours are expressed as your local time.') }}
      </p>
      
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
