<template>  
    <div
    class="form-group text-center m-t-20"
    style="top: 0px; min-height: 230px"
    :class="{ 'fade-out': success }"
    ref="touchArea"                     
    >
    <!--
    <div class="loading" style="height:0.25em">
        <div
        v-if="isLoading"
        style="height:0.25em;width:20%;"
        class="animation-loading"
        ></div>
    </div>
    -->
    <div class="lockpattern" draggable="false" style="top: 0px">
        <svg
        id="pattern-login"
        ref="svg"
        viewBox="0 0 30 30"
        draggable="false"
        ondragstart="return false;"
        :class="{ 'success': success, 'error': error }"
        >
        <defs>
            <filter id="glow" width="1.5" height="1.5" x="-.25" y="-.25">
            <feGaussianBlur stdDeviation="0.25" result="coloredBlur" />
            <feMerge>
                <feMergeNode in="coloredBlur" />
                <feMergeNode in="SourceGraphic" />
            </feMerge>
            </filter>
        </defs>
        <path
            style="-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;user-select: none;-webkit-user-drag: none;user-drag: none;"
            draggable="false"
            ondragstart="return false;"
            ref="indicator"
            id="indicator"
            :d="pathToSvg"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <circle
            style="-moz-user-select: none;-webkit-user-select: none;-ms-user-select: none;user-select: none;-webkit-user-drag: none;user-drag: none;"
            draggable="false"
            ondragstart="return false;"
            v-for="coord, i in matrix"
            :key="i"
            ref="circle"
            :cx="coord[0]"
            :cy="coord[1]"
            r="4"
            :class="{ 'glow': (glowMatrix[i]) }"
            :data-key="i"
        />
        </svg>

        <form
        id="login-form"
        method="post"
        action="phpApi/login.json"
        style="margin-top:-9999px"
        >
        <input
            id="pattern-input"
            name="pattern"
            type="number"
            min="0"
            max="876543210"
            :value="pathToString"
        />
        <button type="submit">Log in</button>
        </form>
    </div>
    </div>
</template>

<script>
import { AuthService } from "@/services/auth.service";


export default {
  name: "isa-loginform",
  props: {
    cerror: Boolean,
    csuccess: Boolean,
  },
  model: {
    prop: 'checked',
    event: 'pattern'
  },
  components: {
    
  },
  watch: {
      "cerror":  function(val, oldval) {
          this.error = true;        
           console.log('set error to ' + true)            
          
          this.backToNormal()    ;   
          
      },
      "csuccess": function(val, oldval) {
          this.success = true;
          console.log('set success to ' + true)
          
            this.backToNormal();
      },
  },
  data() {
    return {
      matrix: [
        //0:
        [5, 5],
        //1:
        [15, 5],
        //2:
        [25, 5],
        //3:
        [5, 15],
        //4:
        [15, 15],
        //5:
        [25, 15],
        //6:
        [5, 25],
        //7:
        [15, 25],
        //8:
        [25, 25]
      ],
      path: [],
      trackMouseMove: false,
      screenX: 0,
      screenY: 0,      
      password: "",
      isLoading: false,
      firstPattern: false,
      firstPatternData: null,
      readyToSave: false,
      text: "PLease enter your password first.",      
      passwordChecked: false,
      showChangePatternModal: false,
      success: false,
      error: false,
    };
  },
  created() {
    this.$nextTick(function() {
      window.addEventListener("touchmove", this.mousemoveANDtouchmove);
      window.addEventListener("mousemove", this.mousemoveANDtouchmove);
      window.addEventListener("mouseup", this.touchendANDmouseup);
      window.addEventListener("touchend", this.touchendANDmouseup);
      window.addEventListener("mousedown", this.touchstartANDmousedown);
      window.addEventListener("touchstart", this.touchstartANDmousedown);
    });
  },
  destroyed() {
    if (this.$refs && this.$refs.touchArea) {
      window.removeEventListener("mousedown", this.touchstartANDmousedown);
      window.removeEventListener("touchdown", this.touchstartANDmousedown);
      window.removeEventListener("mouseup", this.touchendANDmouseup);
      window.removeEventListener("touchend", this.touchendANDmouseup);
      window.removeEventListener("touchmove", this.mousemoveANDtouchmove);
      window.removeEventListener("mousemove", this.mousemoveANDtouchmove);
    }
  },
  computed: {
    pathToString() {
      return this.path.join("");
    },
    glowMatrix() {
      return this.matrix.map(
        function(n, i) {
          return this.path.includes(i);
        }.bind(this)
      );
    },
    pathToSvg() {
      if (!this.path.length) return "";
      
      if (this.screenX && this.screenY && this.trackMouseMove)
      {
        if(!this.$refs.svg || !this.$refs.svg.getBoundingClientRect) return "";
        var svgB = this.$refs.svg.getBoundingClientRect();
      
      return (
        this.path
          .map(
            function(n, i) {
              return (
                (i ? " L " : "M ") + this.matrix[n][0] + " " + this.matrix[n][1]
              );
            }.bind(this)
          )
          .join("") +
        (this.screenX && this.screenY && this.trackMouseMove
          ? " L " +
            ((this.screenX - svgB.left) * 30) / svgB.width +
            " " +
            ((this.screenY - svgB.top) * 30) / svgB.width
          : "")
      );
     }
    }
  },  
  methods: {
    delay: ms => new Promise(res => {
        setTimeout(res, ms)
      }),

    async backToNormal() {    
      await this.delay(1000)            
      this.success = false;
      this.error = false;
      this.path = [];      
    },


    touchstartANDmousedown(ev) {
      this.error = false;
      this.success = false;

      this.trackMouseMove = true;
      this.path = [];
      // avoid scrolling when using touch
      // document.body.style.overflow = "hidden";
    },
    touchendANDmouseup(ev) {
      this.trackMouseMove = false;
      // document.body.style.overflow = "auto";

      // this.login();
      var vm = this;
      if (!this.path.length) return false;
      vm.success = false;
      vm.error = false;
      var pattern = vm.pathToString.toString();
      this.$emit('pattern',pattern)
      this.path = [];

    },
    mousemoveANDtouchmove(ev) {
      if (!this.trackMouseMove) return false;

      if (ev.type == "mousemove") {
        var target = document.elementFromPoint(ev.clientX, ev.clientY);
        this.screenX = ev.clientX;
        this.screenY = ev.clientY;
      } else {
        var myLocation = ev.changedTouches[0];
        var target = document.elementFromPoint(
          myLocation.clientX,
          myLocation.clientY
        );
        this.screenX = myLocation.clientX;
        this.screenY = myLocation.clientY;
      }
      if(!target)
        return false;
      let keyAsString = target.getAttribute("data-key");
      let key = undefined;

      if (keyAsString) key = Number(keyAsString);
      else return false;

      /*
          This part of the code is a little bit nasty.
          For performance reasons, when moving the finger or mouse fast enough,
          there was a chance that one would "skip" one of the touchable dots.
          So this code looks for the currently touched dot and the previus one
          and fill in the blank with the dot that may have left inbetween
       */

      let last_key = this.path[this.path.length - 1];

      if (last_key == 0 && key == 2) this.path.push(1);
      if (last_key == 0 && key == 6) this.path.push(3);
      if (last_key == 0 && key == 8) this.path.push(4);

      if (last_key == 1 && key == 7) this.path.push(4);

      if (last_key == 2 && key == 1) this.path.push(1);
      if (last_key == 2 && key == 6) this.path.push(4);
      if (last_key == 2 && key == 8) this.path.push(5);

      if (last_key == 3 && key == 5) this.path.push(4);

      //4

      if (last_key == 5 && key == 3) this.path.push(4);

      if (last_key == 6 && key == 0) this.path.push(3);
      if (last_key == 6 && key == 8) this.path.push(7);
      if (last_key == 6 && key == 4) this.path.push(4);

      if (last_key == 7 && key == 1) this.path.push(4);

      if (last_key == 8 && key == 0) this.path.push(4);
      if (last_key == 8 && key == 2) this.path.push(5);
      if (last_key == 8 && key == 6) this.path.push(7);

      if (!this.path.includes(key)) this.path.push(key);
    },
    login() {
      var vm = this;

      if (!this.path.length) return false;

      vm.success = false;
      vm.error = false;

      var pattern = vm.pathToString.toString();

      /* fake request. Will allways fail every n of times, and succeed every n+1 times */
      window["times_tried"] = window["times_tried"] + 1 || 0;
      this.isLoading = true;
      var vm = this;      
      if(!this.firstPattern) {
        this.firstPattern = true;        
        this.firstPatternData = pattern;
        console.log("FIRST PATTERN: " + pattern)
        vm.success = true
        this.text = "Please enter you pattern again, just to be sure"
        window.setTimeout(function() {            
            vm.success = false;
            vm.path = [];
          }, 1000);
      } else {
        console.log("FIRST PATTERN: " + this.firstPatternData)
        console.log("SECOND PATTERN: " + pattern)
        if(pattern === this.firstPatternData)
        { // success, we can save this patter          
          vm.success = true;
          this.text = "This is correct, your lock pattern is ready.\nPlease press save and next time, you can use your pattern to log in"
          this.readyToSave = true;
          this.readyToSaveData = pattern;
          window.setTimeout(function() {
              vm.success = false;
            }, 35000);
          
            
        } // different pattern, let's restart
        else
        {
          vm.error = true;
          this.firstPattern = false
          this.firstPatternData = null
          this.text = "Patterns does not match. Please try again."     
          setTimeout(() => {
            this.text = "Please define your lock pattern."     
              vm.error = false;
              vm.path = [];                       
          }, 2500);
        }
        
        
      }
    },

  }
};
</script>

<style lang="scss" scoped>
.isamodal-content {
  min-height: 640px !important;
  width: 100%;
  margin-top: 20px;
  margin-bottom: auto;
  background: white;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.brand_logo {
  position: absolute;
  text-align: center;
  height: 55px;
}
.form_container {
  margin-top: 100px;
}
.login_btn {
  width: 100%;
  background: #36958d !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: #36958d !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #c0392b !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

.btn:focus {
  outline: none !important;
}

.btn-secondary {
  border-color: white;
}

/*Atomic CSS components (tachyons inspired)*/
.overflow-y-auto {
  overflow-y: auto;
}
.fixed {
  position: fixed;
}
.top-0 {
  top: 0;
}
.bottom-0 {
  bottom: 0;
}
.left-0 {
  left: 0;
}
.right-0 {
  right: 0;
}

/*Custom CSS*/
.fade-out {
  animation: fade-out 0.4s;
  animation-delay: 0.1s;
  animation-fill-mode: forwards;
  opacity: 1;
}
@keyframes fade-out {
  to {
    opacity: 0;
  }
}

.lockpattern {
  padding: 0vmin;
  box-sizing: border-box;
  text-align: center;
  user-select: none;
}
svg#pattern-login {
  cursor: pointer;
  width: 240px;
  height: 240px;
  max-width: 80vmin;
  max-height: 80vmin;
  fill: rgba(136, 202, 190, 0.1);
  stroke: rgb(35, 37, 35);
  stroke-width: 0.05px;
  display: inline-block;
}

svg#pattern-login circle.glow,
svg#pattern-login path#indicator {
  stroke: #37f;
}
.glow {
  filter: url(#glow);
}

svg#pattern-login.success circle.glow,
svg#pattern-login.success path#indicator {
  stroke: #3f7;
}
svg#pattern-login.error circle.glow,
svg#pattern-login.error path#indicator {
  stroke: #f33;
}

.animation-loading {
  background-color: #37f;
  animation: loading 1s linear infinite;
  transform: translateX(-100%);
}

@keyframes loading {
  to {
    transform: translateX(500%);
  }
}
</style>
