<template>
     <div>
      <h1>{{ $t('help.ISAInsightRoomTotalBedExit_title') }}</h1>
      <p class="text-justify">
          {{ $t('The total amount of Bed Exits.') }}'
      </p>
      
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
