<template>
    <ISASound />
</template>

<script>
    import { SPService } from '@/services/sp.service.js'
    import { AuthService } from '@/services/auth.service.js'
    import { deviceDetect } from "mobile-device-detect";
    import { VERSIONDATE } from '@/version.js';
    import { API_URL } from '@/.env';
    import ISASound from '@/components/ISA/kiosk/ISASound';

    let _sseServer = null;
    let _sseAbortTimeout = null;
    export default {
        name: 'ISAShortPolling',
        components: {
            ISASound,
        },
        props: {
            interval: String
        },
        created() {
            window.addEventListener('resize', this.onResize, { passive: true })
            setInterval(() => {
                this.$store.commit('fleet/CRON_MINUTE');
            }, 60000);
            
        },
        watch: {
            "$store.state.fleet.currentOrganization": function (value) {
                if(!this.$store.state.fleet.currentOrganization)
                    this.$store.dispatch('fleet/setCurrentOrganization', this.$store.state.user.currentUser.organizationLabel || this.$store.state.user.kioskUser.organizationLabel, { root: true });
                this.sseConnect();
                
            },
            "$store.state.fleet.reloadSSE": function (value) {
                if(!this.$store.state.fleet.currentOrganization)
                    this.$store.dispatch('fleet/setCurrentOrganization', this.$store.state.user.currentUser.organizationLabel || this.$store.state.user.kioskUser.organizationLabel, { root: true });
                if(value) {
                    console.log('in force reload SSE watcher')
                    this.sseConnect();
                }                
            }
        },
        data() {
            return {
                spCount: 0,
                errCount: 0,
                authErrorCount: 0,
                responses: [],
                lastSSEMessage: new Date(),
                lastCheckAuth: new Date(),
                webKeepAlive: 7000,
            }
        },
        methods: {
            async checkAuth() {
                try {

                    if(!this.$store.state.fleet.currentOrganization && (this.$store.state.user.currentUser.oid || this.$store.state.user.kioskUser.oid))
                    {
                    
                        await this.$store.dispatch('fleet/setCurrentOrganization', this.$store.state.user.currentUser.organizationLabel || this.$store.state.user.kioskUser.organizationLabel, { root: true });
                    }

                    if((new Date(this.lastCheckAuth) > 10000))
                    {
                        if (!this.$store.state.user.currentUser.jwt && this.$store.state.user.kioskUser.jwt) // switch to kiosk is user not logged
                        {
                            console.log('switching to kiosk user in checkAuth')
                            await this.$store.commit('user/SET_CURRENT_USER', this.$store.state.user.kioskUser, { root: true })
                        }

                        if (this.$store.state.user.currentUser.role > 0) // if logged user
                        {
                            const authStatus = await SPService.checkAuth(API_URL.url, this.$store.state.user.currentUser.jwt);
                            if (authStatus > 0)
                                this.authErrorCount += authStatus;
                            else
                                this.authErrorCount = 0;

                            if (this.authErrorCount > 1)
                                this.sseConnect();
                            if (this.authErrorCount > 4) {
                                if (this.$store.state.user.kioskUser.jwt) // a kiosk user exists, we set it as current user and notify that session has expired
                                {
                                    console.log('switching to kiosk user in checkAuth 2')
                                    await this.$store.dispatch('fleet/resetDataCache');
                                    await this.$store.commit('user/SET_CURRENT_USER', this.$store.state.user.kioskUser, { root: true })
                                    if (this.$router.history.current.fullPath !== '/app/kiosk') // if we are not on kiosk page, nagigate to it
                                        this.$router.push('/app/kiosk');
                                    this.$store.dispatch("closeSidebar"); // close side menu because we are kiosk only here

                                    this.$bvToast.toast(this.$t('You session has expired'), {
                                        title: this.$t('User Session'),
                                        solid: true,
                                        variant: 'warning',
                                        duration: 5000
                                    });
                                } else { // no kiosk user, we need to logout then
                                    await this.$store.dispatch('user/logout', null, { root: true })
                                    this.$router.push('/app/sessions/signIn');
                                }
                            }
                        }

                        // if disconnected since more than 10s, reconnect SSE
                        if (!this.$store.state.fleet.sseState && this.$store.state.fleet.sseStateChangeDate && (new Date() - this.$store.state.fleet.sseStateChangeDate) > 10000) {
                            this.sseConnect();
                        }
                    }

                    // if no message since more than 40s, reconnect SSE
                    if ((new Date() - this.lastSSEMessage) > 2.2*this.webKeepAlive) {
                        console.log('new Date() - this.lastSSEMessage = ' + (new Date() - this.lastSSEMessage))
                        this.sseConnect();
                    }

                } catch (err) {
                    this.errCount++;
                    if (this.errCount === 3) {
                        this.$bvToast.toast(`${this.$t('Synchronization error')}. ${err.status}: ${this.$t(err.message)}`, {
                            title: this.$t('Connexion Error'),
                            solid: true,
                            variant: 'error',
                            duration: 5000
                        });
                    }
                }
                this.lastCheckAuth = new Date();
                setTimeout(this.checkAuth.bind(this), 6000);
            },
            async sseConnect() {
                if(!this.$store.state.fleet.currentOrganization)
                    this.$store.dispatch('fleet/setCurrentOrganization', this.$store.state.user.currentUser.organizationLabel || this.$store.state.user.kioskUser.organizationLabel, { root: true });

                if (this.SSEConnecting) return;
                
                this.SSEConnecting = true;
                try {
                    if (_sseServer)
                    try {
                        _sseServer.close();
                    } catch (err) {
                        console.error(err);
                    }

                this.$store.dispatch('fleet/shortPollSSE', { type: 'error', data: 'reconnecting' });
                if (_sseAbortTimeout) {
                    clearTimeout(_sseAbortTimeout);
                    _sseAbortTimeout = null;
                }
                /*
                if (!this.$store.state.user.currentUser.jwt && this.$store.state.user.kioskUser.jwt) // switch to kiosk is user not logged
                {
                    console.log('switching to kiosk user in sseConnect');
                    await this.$store.commit('user/SET_CURRENT_USER', this.$store.state.user.kioskUser, { root: true })
                }*/
                    

                if (!API_URL || !this.$store.state.user.currentUser.jwt) {
                    setTimeout(this.sseConnect.bind(this), 1000);
                    console.log('SSE cannot connect, no active user');
                    this.SSEConnecting = false;
                    return;
                };

                if (this.lastSSEConnectionAttempt && new Date() - this.lastSSEConnectionAttempt < 1000)
                    await new Promise((resolve) => {
                        setTimeout(resolve, 2000);
                    });
                this.lastSSEConnectionAttempt = new Date();
                const url = await SPService.getSSEURL(API_URL.url, this.$store.state.user.currentUser.jwt, this.$store.state.fleet.currentOrganization);
                if (!url.data) {
                    if(url.code === 1017) {
                        console.log('url.code = 1017')
                        await AuthService.makeLogoutKiosk()
                        if(!this.$store.state.user.currentUser.oid)
                            this.$router.push('/app/sessions/signIn')
                        return;
                    } 


                    setTimeout(this.sseConnect.bind(this), 1000);
                    console.log('SSE cannot connect, bad url returned')
                    this.SSEConnecting = false;
                    return;
                }
                this.webKeepAlive = url.data.wka;
                if(url && url.data && url.data.url)
                    this.$sse(API_URL.url + '/short-polling/spsse/' + url.data.url, { format: 'json' }) // or { format: 'plain' }
                        .then(sse => {
                            // Store SSE object at a higher scope
                            _sseServer = sse;
                            this.$store.dispatch('fleet/shortPollSSE', { type: 'open' });
                            this.lastSSEMessage = new Date();
                            if (_sseAbortTimeout) {
                                clearTimeout(_sseAbortTimeout);
                                _sseAbortTimeout = null;
                            }

                            // Catch any errors (ie. lost connections, etc.)
                            sse.onError(e => {
                                console.error('lost connection; giving up!', e);                            
                                // This is purely for example; EventSource will automatically
                                // attempt to reconnect indefinitely, with no action needed
                                // on your part to resubscribe to events once (if) reconnected
                                try
                                {
                                    // sse.close();
                                } catch(err) {

                                }
                                

                                this.$store.dispatch('fleet/shortPollSSE', { type: 'error', data: e });
                                //setTimeout(this.sseConnect.bind(this), 1000);
                                if (!_sseAbortTimeout)
                                    _sseAbortTimeout = setTimeout(this.sseConnect.bind(this), 1000);
                            });

                            // Listen for messages without a specified event
                            sse.subscribe('', (message, rawEvent) => {
                                this.$store.dispatch('fleet/shortPollSSE', { type: 'message', data: message });
                                            
                                if (message.type === 'all' && !message.data) { // don't know why, but received an empty payload here, reconnect to try
                                     setTimeout(this.sseConnect.bind(this), 1000);
                                    this.SSEConnecting = false;                                    
                                }
                                else {                                
                                    this.lastSSEMessage = new Date();
                                    if (_sseAbortTimeout) {
                                        clearTimeout(_sseAbortTimeout);
                                        _sseAbortTimeout = null;
                                    }
                                }                            
                            });
                        })
                        .catch(err => {
                            // When this error is caught, it means the initial connection to the
                            // events server failed.  No automatic attempts to reconnect will be made.
                            if (_sseAbortTimeout) {
                                clearTimeout(_sseAbortTimeout);
                                _sseAbortTimeout = null;
                            }
                            // debugger
                            setTimeout(this.sseConnect.bind(this), 3000);
                        });
                    } catch (globalErr) {
                        //debugger
                        console.error(globalErr);
                    }
                this.SSEConnecting = false;
                
                

                /*const listener = (event) => {
                  console.log(event)       ;
                  this.$store.dispatch('fleet/shortPollSSE', event);
                };
                try
                {
                  return SPService.shortpollSSE(API_URL.url, this.$store.state.user.currentUser.jwt,listener.bind(this));
                } catch(err) {
                  console.error(err);
                }*/

            },
            onResize() {
                let isMobile = (deviceDetect().isMobileOnly || window.outerHeight > window.outerWidth) ? true : false;
                this.$store.commit('user/SET_ISMOBILE', isMobile || window.isamobile);
            },
            async checkMinimalVersion() {
                try {
                    const l = await SPService.alive(API_URL.url);
                    if (l.data.frontendVersion && l.data.frontendVersion > VERSIONDATE) {
                        
                            if(window.location.hostname !== 'localhost') {
                                const data = {
                                action: 'reload',
                                data: l.data.frontendVersion,
                            }
                            try {
                                
                                await SPService.wbevent(API_URL.url, this.$store.state.user.currentUser.jwt, data);
                            } catch (err) {

                            }
                            
                             window.location.reload();
                        }
                        
                    }
                } catch (err) {

                }
                setTimeout(this.checkMinimalVersion.bind(this), 60000);
            }
        },

        computed: {
            classList() {
                return {
                    default: this.item.type === 'default',
                    success: this.item.type === 'success',
                    info: this.item.type === 'info',
                    warning: this.item.type === 'warning',
                    error: this.item.type === 'error'
                }
            }
        },
        beforeDestroy() {
            // Make sure to close the connection with the events server
            // when the component is destroyed, or we'll have ghost connections!
            if (_sseServer) _sseServer.close();
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', this.onResize, { passive: true })
            }
        },
        

        mounted() {
            if(!this.$store.state.fleet.currentOrganization)
                    this.$store.dispatch('fleet/setCurrentOrganization', this.$store.state.user.currentUser.organizationLabel || this.$store.state.user.kioskUser.organizationLabel, { root: true });


            this.$store.dispatch('fleet/shortPollSSE', { type: 'error', data: null });
            this.checkAuth();
            
            
            this.sseConnect();

            this.onResize()
           

            this.checkMinimalVersion();
            const self = this;
            
        }
    }
</script>

<style lang="scss">
</style>
