<template>
  <div class="d-flex justify-content-center">
    <ISALanguageSelector style="top: 0; right: 2rem;position: absolute"/>
    <img :src="IMGService.isalogo" class="brand_logo" alt="Logo" style="margin-top: 20px" />
    <div class="form_container">
      <form style="margin-top: 20px" autocomplete="off" id="login_form">
        <div class="user-thumb text-center m-b-30">                     
          <img :src="IMGService.isalock"
          class="rounded-circle img-thumbnail"
          alt="thumbnail"
          style="height: 80px; padding-top:-10px"
          v-b-toggle.collapseAdvanced />
        </div>
        <div v-if="changePwd">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(applyChangePassword)" id="password_required_change">
              <h5 style="margin-top: 7px; width: 100%" class="text-12">{{ $t('You need to change your password')}}</h5>
              <validation-provider name="NewPassword"
                :rules="{ required: true, required: true, min: 5, max: 250 }"
                v-slot="validationContext">
                <b-form-group :label="$t('Please enter your new password here')" class="text-12">
                  <b-form-input class="form-control-rounded password"
                    type="text"
                    v-model="changePassword1"
                    autocomplete="off" readonly 
                    @focus="removeReadonly"                                                                                                    
                    @keypress="makeLoginOnEnter"
                    aria-describedby="input-1-live-feedback"
                    :state="getValidationState(validationContext)"></b-form-input>
                  <b-form-invalid-feedback id="input-1-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <validation-provider :name="$t('New Password Confirmation')"
                rules="required|password:@NewPassword"
                v-slot="validationContext">
                <b-form-group :label="$t('Please confirm your new password')" class="text-12">
                  <b-form-input class="form-control-rounded password"
                    type="text"
                    v-model="changePassword2"
                    autocomplete="off" readonly 
                    @focus="removeReadonly"                                                  
                    @keypress="makeLoginOnEnter"
                    aria-describedby="input-2-live-feedback"
                    :state="getValidationState(validationContext)"></b-form-input>
                  <b-form-invalid-feedback id="input-2-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>

              <div class="input-group mb-3" style="margin-top: -7px; width: 100%">
                <b-button type="submit"
                  tag="button"
                  class="btn-rounded btn-block mt-2"
                  variant="primary mt-2">
                  {{ $t('Reset Password') }}
                </b-button>
              </div>

            </b-form>
          </validation-observer>
        </div>
        <div v-if="!changePwd && hasKnownUsers && isPatternActivated && !$store.state.user.needsKioskLogin">
          <b-tabs justified pills end active v-model="tabIndex">
            <b-tab 
              :title="$t('Credential login')"
              :title-link-class="linkClass(0)" 
              style="form-control-rounded">
              <b-form  @submit.prevent="makeLogin" >
                <b-form-group :label="$t('Email Address or Username')" class="text-12">
                  <b-form-input
                    v-if="$store.state.user.kioskable"
                    class="form-control-rounded"
                    type="text"
                    v-model="authorizeUsername"
                    placeholder="username"
                    autocomplete="off" readonly 
                    @focus="removeReadonly"
                    required
                    :disabled="$store.state.user.needsKioskLogin && $router.currentRoute.path !== '/app/sessions/signIn'"                                                  
                    id="username_field"
                    @keypress="makeLoginOnEnter"></b-form-input>

                  <b-form-input
                    v-else
                    class="form-control-rounded"
                    type="text"
                    v-model="authorizeUsername"
                    placeholder="username"                                                  
                    required
                    :disabled="$store.state.user.needsKioskLogin && $router.currentRoute.path !== '/app/sessions/signIn'"                                                  
                    id="username_field"
                    @keypress="makeLoginOnEnter"></b-form-input>
                </b-form-group>

                <b-form-group :label="$t('Password')" class="text-12">
                  <b-form-input v-if="$store.state.user.kioskable"
                    class="form-control-rounded password"
                    type="text"
                    v-model="authorizePassword"                                                  
                    autocomplete="off" readonly 
                    @focus="removeReadonly"
                    required                                                  
                    @keypress="makeLoginOnEnter"
                    id="password_field"></b-form-input>

                  <b-form-input v-else
                    class="form-control-rounded "
                    type="password"
                    v-model="authorizePassword"                                                  
                    required                                                  
                    @keypress="makeLoginOnEnter"
                    id="password_field"></b-form-input>
                </b-form-group>

                <b-form-group v-if="$store.state.user.needsKioskLogin && $router.currentRoute.path !== '/app/sessions/signIn'" :label="$t('Organization')" class="text-12">
                  <b-form-input class="form-control-rounded"
                    type="text"
                    v-model="organizationLabel"
                    autocomplete="off" readonly 
                    @focus="removeReadonly"
                    :placeholder="$t('organization')"
                    required                                                  
                    @keypress="makeLoginOnEnter"></b-form-input>
                </b-form-group>

                <div class="input-group mb-3" style="margin-top: 20px; width: 100%">
                  <b-button type="submit"
                    tag="button"
                    class="btn-rounded btn-block mt-2 signinbtn"
                    variant="primary mt-2"
                    id="login_button"
                    :disabled="requesting"
                    @click="makeLogin">
                    {{$t('SignIn')}}
                  </b-button>
                </div>
              </b-form>
              <div class="mt-3 text-center">
                <router-link to="/app/sessions/forgot" tag="a" class="text-muted">
                  <u>{{$t('Forgot Password?')}}</u>
                </router-link>
              </div>
            </b-tab>
            <b-tab 
              :title="$t('Pattern login')" 
              active 
              :title-link-class="linkClass(1)">
              <b-card-text class="justify-content-center">
                <div class="form-group text-center row m-t-20">
                  <div class="col-12" style="display: block; padding-left: 10px;">
                    <select v-model="selectedUser" class="form-control mb-auto mt-auto" style="font-size: 20px; height:45px">
                      <option disabled value="">{{ $t('Please select one') }}</option>
                      <option v-for="u in knownUsers.map(k => k.username)" v-bind:key="'opt_' + u.username" >
                      {{ u }}        
                      </option>
                    </select>
                  </div>
                </div>
                <LockPattern @pattern="onPattern" />
              </b-card-text>
            </b-tab>
          </b-tabs>
        </div>
        <div v-if="!(!changePwd && hasKnownUsers && isPatternActivated && !$store.state.user.needsKioskLogin) && !changePwd" style="padding-top: 10px; height: 315px; position: relative; bottom:0px">
          <b-form @submit.prevent="makeLogin" autocomplete="off">
            <b-form-group :label="$t('Email Address or Username')" class="text-12">
              <b-form-input v-if="$store.state.user.kioskable"
                class="form-control-rounded"
                type="text"
                v-model="authorizeUsername"
                :placeholder="$t('username')"
                autocomplete="off" readonly 
                @focus="removeReadonly"
                required
                :disabled="$store.state.user.needsKioskLogin && $router.currentRoute.path !== '/app/sessions/signIn'"                                          
                id="username_field"
                @keypress="makeLoginOnEnter"></b-form-input>

              <b-form-input v-else
                class="form-control-rounded"
                type="text"
                v-model="authorizeUsername"
                :placeholder="$t('username')"
                required
                :disabled="$store.state.user.needsKioskLogin && $router.currentRoute.path !== '/app/sessions/signIn'"                                          
                id="username_field"
                @keypress="makeLoginOnEnter"></b-form-input>



            </b-form-group>

            <b-form-group :label="$t('Password')" class="text-12">
              <b-form-input v-if="$store.state.user.kioskable"
                class="form-control-rounded password"
                type="text"
                v-model="authorizePassword"                                          
                autocomplete="off" readonly 
                @focus="removeReadonly"
                required                                          
                id="password_field"
                @keypress="makeLoginOnEnter"></b-form-input>

              <b-form-input v-else
                class="form-control-rounded "
                type="password"
                v-model="authorizePassword"                                                                                    
                required                                          
                id="password_field"
                @keypress="makeLoginOnEnter"></b-form-input>


            </b-form-group>

            <b-form-group v-if="$store.state.user.needsKioskLogin && $router.currentRoute.path !== '/app/sessions/signIn'" :label="$t('Organization')" class="text-12">
              <b-form-input class="form-control-rounded"
                type="text"
                v-model="organizationLabel"
                placeholder="organizationLabel"
                autocomplete="off" readonly 
                @focus="removeReadonly"
                required                                          
                @keypress="makeLoginOnEnter"></b-form-input>
            </b-form-group>

            <div class="input-group mb-3" style="margin-top: 20px; width: 100%">
              <b-button type="submit"
                tag="button"
                class="btn-rounded btn-block mt-2 signinbtn"
                variant="primary mt-2"
                :disabled="requesting"
                id="login_button"
                @click="makeLogin">
                {{ $t('Sign In') }}
              </b-button>
            </div>
          </b-form>
          <div class="mt-3 text-center">
            <router-link to="forgot" tag="a" class="text-muted" v-if="!modal" id="forgot_password">
              <u>{{$t('Forgot Password?')}}</u>
            </router-link>
            <a class="text-muted" v-if="modal" @click="forgotClick"><u>{{$t('Forgot Password?')}}</u></a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
  import { AuthService } from "@/services/auth.service";    
  import { SPService } from "@/services/sp.service";
  import LockPattern from "./LockPattern.vue";
  import CryptoJS from 'crypto-js';
  import { API_URL } from '@/.env'
  import { extend } from 'vee-validate';
  import ISALanguageSelector from './ISALanguageSelector';
  import { IMGService } from '@/services/img.service'

  extend('password', {
    params: ['target'],
    validate(value, { target }) {
      return value === target;
    },
    message: 'Password confirmation does not match'
  });

  export default {
    name: "isa-loginform",
    props: {
      method: { type: Function },
      modal: { type: Boolean, default: false },
      changePwd: { type: Boolean, default: false },
      changePasswordMethod: { type: Function },
    },
    data() {
      return {
        IMGService,
        authorizeUsername: "",
        authorizePassword: "",
        organizationLabel: "",
        apiUrl: API_URL.url,
        requesting: false,
        error: "",
        tabIndex: 0,
        selectedUser: null,

        changePassword1: null,
        changePassword2: null,
      };
    },
    computed: {
      isPatternActivated() {
        return this.$store.state.user.deviceIsKiosk;
      },
      hasKnownUsers() {
        return this.$store.state.user.knownUsers.length > 0 && this.$store.state.user.kioskUser.username;
      },
      knownUsers() {
        this.selectedUser = this.$store.state.user.knownUsers.length ? this.$store.state.user.knownUsers[0].username : null;
        return this.$store.state.user.knownUsers.filter(u => u.encryptedPassword)
      },
    },
    components: {
      LockPattern,
      ISALanguageSelector,
    },
    mounted() {
      if (this.$store.state.user.knownUsers.length > 0)
        this.tabIndex = 1 // lockpattern by default if known users
      if (this.$store.state.user.needsKioskLogin && this.$router.currentRoute.path !== '/app/sessions/signIn')
        this.authorizeUsername = 'kiosk';
    },
    methods: {
      removeReadonly(e) {
        e.target.removeAttribute('readonly');                
      },
      makeLoginOnEnter(e) {
        if (e.keyCode === 13) {
          this.makeLogin();
        }
      },
      async onPattern(pattern) {
        try {
          let user = this.$store.state.user.knownUsers.find(u => u.username === this.selectedUser);
          this.requesting = true;
          const p = CryptoJS.AES.decrypt(user.encryptedPassword, pattern).toString(CryptoJS.enc.Utf8);
          if (!p) {
            SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
              action: 'pattern_wrong',
              path: window.location.pathname,
              data: window.location.href,
            });

            return this.$bvToast.toast(this.$t('This lock pattern is wrong. It you forgot your pattern, please signup using your credentials then reset your pattern.'), {
              title: this.$t('Bad pattern'),
              solid: true,
              variant: 'warning',
              duration: 5000
            });
          }
          let newUser = await AuthService.makeLogin({
            apiUrl: user.apiUrl,
            username: this.selectedUser,
            password: p,
            organizationLabel: user.organizationLabel || "mintt"
          });
          this.error = "";

          if (newUser && newUser.status == 200) {

            newUser.data.organizationLabel = user.organizationLabel
            newUser.data.apiUrl = user.apiUrl
            await this.$store.dispatch("user/setCurrent", { user: newUser.data, password: this.authorizePassword });
            this.$emit("loginSuccess", newUser.data);
            SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
              action: 'pattern_good',
              path: window.location.pathname,
              data: window.location.href,
            });

          } else {
            if (newUser.status === 400) {
              this.$bvToast.toast(this.$t('This lock pattern is wrong. It you forgot your pattern, please signup using your credentials then reset your pattern.'), {
                title: this.$t('Login Error'),
                solid: true,
                variant: 'warning',
                duration: 5000
              });
              this.$emit("loginError", newUser.data);
              SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                action: 'pattern_wrong',
                path: window.location.pathname,
                data: window.location.href,
              });
            } else {
              this.$bvToast.toast(this.$t('This lock pattern is wrong. It you forgot your pattern, please signup using your credentials then reset your pattern.'), {
                title: this.$t('Login Error'),
                solid: true,
                variant: 'warning',
                duration: 5000
              });
              this.$emit("loginError", newUser.data);
              SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                action: 'pattern_wrong',
                path: window.location.pathname,
                data: window.location.href,
              });
            }
          }
        } catch (error) {
          console.error(error.status);
          console.error(error);

          if (error.status === 400) {
            this.$bvToast.toast(this.$t(error.message), {
              title: this.$t('Login Error'),
              solid: true,
              variant: 'warning',
              duration: 5000
            });
          } else {

          }

          this.error =
            error.status === 404
            ? "User with same email not found"
            : error.message;
        }
        this.requesting = false;
      },
      async makeLogin() {
        try {
          this.requesting = true;
          let newUser = await AuthService.makeLogin({
            apiUrl: this.apiUrl,
            username: this.authorizeUsername,
            password: this.authorizePassword,
            organizationLabel: '*'
          });
          this.error = "";

          if (newUser.status == 200) {
            newUser.data.apiUrl = this.apiUrl
            await this.$store.dispatch("user/setCurrent", { user: newUser.data, password: this.authorizePassword });
            await this.$store.dispatch("fleet/setCurrentOrganization", newUser.data.organizationLabel);
        
            SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
              action: 'login_done',
              path: window.location.pathname,
              data: window.location.href,
            });

            //  OneSignalService.setIdentifiers(newUser.data.oid, newUser.data.username, this.$store.state.fleet.currentOrganization);
            this.$emit("loginSuccess", newUser.data);
            if (newUser.data.needNewPassword) {
              this.changePwdJWT = newUser.data.jwt;
              this.changePwd = true;
            } else {
              this.$bvToast.toast(this.$t('Hello') + ` ${newUser.data.firstName ? newUser.data.firstName : newUser.data.username}, ` + this.$t('you have successfully signed in.'), {
                title: this.$t('Login Success'),
                solid: true,
                variant: 'success',
                duration: 5000
              });
              if (this.$router.currentRoute.path === '/app/sessions/signIn')
              {
                if(this.$store.state.user.beforeSignInUrl)
                {
                  setTimeout(() => {
                    this.$router.push(this.$store.state.user.beforeSignInUrl);
                    this.$store.commit('user/SET_URLBEFORESIGNIN',null);
                  }, 500);
                }        
                else
                  setTimeout(() => {
                    this.$router.push("/");
                  }, 500);                            
              }
            }
          } else {
            this.$bvToast.toast(newUser.status + ": " + newUser.data.message, {
              title: this.$t('Login Error'),
              solid: true,
              variant: 'warning',
              duration: 5000
            });
            this.$emit("loginError", newUser.data);
          }
        } catch (error) {
          console.error(error.status);
          console.error(error);
          this.$bvToast.toast(this.$t(error.message), {
            title: this.$t('Login Error'),
            solid: true,
            variant: 'warning',
            duration: 5000,
            id: "login_page_alert"
          });

          this.error =
            error.status === 404
            ? "User with same email not found"
            : error.message;
        }
        this.requesting = false;
      },
      linkClass(idx) {
        if (this.tabIndex === idx) {
          return ['isa-primary', 'text-light']
        } else {
          return ['bg-light', 'text-info']
        }
      },

      forgotClick() {
        this.$store.dispatch("user/needsLogin", false);
        this.$store.dispatch("user/needsKioskLogin", false);
        this.$store.dispatch("user/needsForgot", true);
      },

      async applyChangePassword() {
        let result = null;
        if(this.changePasswordMethod)
        {
          try {
            result = await AuthService.changePassword(API_URL.url, this.$store.state.user.currentUser.jwt, this.authorizePassword, this.changePassword1);
            this.changePwdJWT = '';
            this.changePwd = false;
            if (result.status === 200) {
              SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, {                        
                action: 'password_change',
                path: window.location.pathname,
                data: window.location.href,
              });
              this.$bvToast.toast(this.$t('New password has been set, please sign in'), {
                title: `Password Reset`,
                variant: "success",
                solid: true,
                duration: 5000,
                id: "reset_pw_alert"
              });
              const self = this;
              setTimeout(() => {
                self.changePwd = false;
                self.$router.push('/');
              }, 2500);

            }
            return this.changePasswordMethod();
          } catch (err) {
            this.$bvToast.toast(this.$t(result.message), {
              title: this.$t('Password Reset'),
              variant: "warning",
              solid: true,
              duration: 5000
            });
          }
        }

        try {
          result = await AuthService.changePassword(API_URL.url, this.changePwdJWT, this.authorizePassword, this.changePassword1);
          this.changePwdJWT = '';
          this.changePwd = false;
          await AuthService.makeLogout()
          this.$router.push('/app/sessions/signIn')
        } catch (err) {
          result = err;
        }
        if (result.status === 200) {
          this.$bvToast.toast(this.$t('New password has been set, please sign in'), {
            title: `Password Reset`,
            variant: "success",
            solid: true,
            duration: 5000,
          });
          const self = this;
          setTimeout(() => {
            self.changePwd = false;
            self.$router.push('/');
          }, 2500);

        } else {
          this.$bvToast.toast(this.$t(result.message), {
            title: this.$t('Password Reset'),
            variant: "warning",
            solid: true,
            duration: 5000
          });
        }
      },

      getValidationState({ dirty, validated, valid = null }) {
        return dirty || validated ? valid : null;
      },
    }
  };
</script>

<style lang="scss" scoped>
  .isa-primary {
    background-color: #36958d !important;
  }

  .user_card {
    height: 400px;
    width: 100%;
    margin-top: 50px;
    margin-bottom: auto;
    background: white;
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .brand_logo {
    position: absolute;
    text-align: center;
    height: 55px;
  }

  .form_container {
    width: 400px;
    margin-top: 100px;
  }

  .login_btn {
    width: 100%;
    background: #36958d !important;
    color: white !important;
  }

  .login_btn:focus {
    box-shadow: none !important;
    outline: 0px !important;
  }

  .login_container {
    padding: 0 2rem;
  }

  .input-group-text {
    background: #36958d !important;
    color: white !important;
    border: 0 !important;
      border-radius: 0.25rem 0 0 0.25rem !important;
  }

  .input_user,
  .input_pass:focus {
    box-shadow: none !important;
    outline: 0px !important;
  }

  .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #c0392b !important;
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #36958d;
    opacity: 0.6; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #36958d;
    opacity: 0.6; /* Firefox */
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #36958d;
    opacity: 0.6; /* Firefox */
  }

  .btn:focus {
    outline: none !important;
  }

  .btn-secondary {
    border-color: white;
  }

  .nav-pills {
    background-color: white;
  }

  .text-12 {
    font-weight: bold;
    color: rgb(0, 0, 0);
  }

  .text-muted {
    font-weight: bold;
    color: rgb(87, 80, 80) !important;
  }

  .signinbtn {
    color: rgb(0, 0, 0);
    font-weight: bold;
  }

  @media screen and (max-width: 640px) {
    .text-muted {
      color: rgb(129, 122, 122) !important;
    }
  }
  .password {
    -webkit-text-security:disc;
    font-family: text-security-disc;
  }
</style>
