<template>
  <b-modal hide-footer v-model="showLoginModal" title="Please authenticate" @hidden="close">
    <ISAForgot :modal="true" />
  </b-modal>
</template>

<script>

import { AuthService } from "@/services/auth.service";
import ISAForgot from "@/components/ISA/session/ISAForgot";

export default {
  components: {
    ISAForgot,
  },
  data() {
    return {
      showLoginModal: false,
    };
  },
  watch: {
    "$store.state.user.needsForgot": function (val) {
      this.showLoginModal = val;
    },
    showLoginModal: function (val) {
      if (!val) {
        this.$store.dispatch("user/needsForgot", false);        
      }
    },
  },
  methods: {
    close() {           
        this.$store.dispatch("user/needsForgot", false);       
    },
    loginSuccess() {
      if (
        this.$store.state.user.needsLogin &&
        this.$store.state.user.needsLoginData
      )
      {        
        this.$store.dispatch(
          this.$store.state.user.needsLoginData.action,
          this.$store.state.user.needsLoginData.args
        );
      }
      if (this.$store.state.user.needsKioskLogin) {
        if (this.$router.currentRoute.path !== "/app/kiosk")
          this.$router.push("/app/kiosk");
      }
      this.$store.dispatch("user/needsForgot", false);      
    },
  },
  mounted() {
    this.showLoginModal = false;
    this.$store.dispatch("user/needsForgot", false);    
  },
  name: "ISAForgotModal",
};
</script>

<style lang="scss" scoped>
</style>
