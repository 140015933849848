<template>
     <div>
      <h1>{{ $t('help.ISASensorOffline_title') }}</h1>
      <p class="text-justify">
          {{ $t('The ISA sensor is offline and will not send alarm. Please verify following:') }}
          <br/>
          <ol>
            <li>{{ $t('Verify the socket or PoE adapter') }}</li>
            <li>{{ $t('Verify the Ethernet cable or the Wifi availability') }}</li>
            <li>{{ $t('Disconnect and reconnect the power.') }}</li>
            <li>{{ $t('Wait 3 min and verify the tiny red led is on.') }}</li>            
          </ol>                   
      </p>
      {{ $t( 'If the ISA sensor remains offline, contact your IT support or contact MintT technical support (support@mintt.care).') }}
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
