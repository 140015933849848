<template>
     <div>
      <h1>{{ $t('help.ISAMonthlyReportLicense_title') }}</h1>
      <p class="text-justify">
          <h3>You're not subscribed to this license.</h3>
          <br/>
          {{
            $('You can upgrade your license to access this feature. Contact your Customer Success Manager or MintT support to enable this feature (<b>support@mintt.care</b>).')
          }}
      </p>
      
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
