<template>
  <div>
      <large-sidebar/>    
    <ISAShortPolling interval=5000 />
    <ISALoginModal/>
    <ISAForgotModal/>
    <ISAChangePattern/>
    <ISAHelpModal/>  
    <ISASavePattern/>
    <router-view name="welcome"/>
  </div>
</template>
<script>
import ISAShortPolling from '@/components/ISA/shortpolling/ISAShortPolling'
import ISALoginModal from '@/components/ISA/session/ISALoginModal';
import ISAChangePattern from '@/components/ISA/session/ISAChangePattern';
import ISAHelpModal from '@/components/ISA/help/ISAHelpModal';
import ISASavePattern from '@/components/ISA/session/ISASavePattern';
import ISAForgotModal from '@/components/ISA/session/ISAForgotModal';

export default {
  data() {
    return {};
  },
  components: {
    ISAShortPolling,
    ISALoginModal,
    ISAForgotModal,
    ISAChangePattern,
    ISAHelpModal,
    ISASavePattern,
  },
}

</script>