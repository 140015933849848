<template>
     <div>
      <h1>{{ $t('help.ISAAlertListDetectionHelp_title') }}</h1>
      <p class="text-justify">
          {{ $t('This is the status of the alert. Each video is seperately analyzed by MintT medical Team.') }}
          <br>
          <br>
          {{ $t('Status explanation:') }}
          <ul>

            <!--------------------------------------------------------------------------------------------------------->
            <li><i style="color: green">
              <font-awesome-icon
                icon="check"
                variant="success"
                style="margin-right: 0.5rem"
          ></font-awesome-icon
          >{{ $t('Verified') }}</i></li>
            {{ $t('The alert has been verified and is validated.') }}            
            
            <!--------------------------------------------------------------------------------------------------------->
            <li>
              <i  style="color: lightgreen"
          ><font-awesome-icon
            icon="user-clock"
            variant="warning"
            style="margin-right: 0.5rem"
          ></font-awesome-icon
          >{{ $t('Not yet verified') }}</i
        >
            </li>            
            {{ $t("Alert validation in progress, usually takes less than 24h.") }}

            <!--------------------------------------------------------------------------------------------------------->
            <li>
              <i  style="color: orange"
          ><font-awesome-icon
            icon="exclamation"
            variant="warning"
            style="margin-right: 0.5rem"
          ></font-awesome-icon
          >{{ $t('False Alert') }}</i
        >
            </li>            
            {{ $t("An ambious situation triggered an alert.") }}

            <!--------------------------------------------------------------------------------------------------------->
            <li>
               <i  style="color: maroon"
          ><font-awesome-icon
            icon="exclamation-circle"
            variant="warning"
            style="margin-right: 0.5rem"
          ></font-awesome-icon
          >{{ $t('Delayed Alert') }}</i
        >
            </li>            
            {{ $t("The alert has not been notified in real time due to an ambiguous situation.") }}
            <!--------------------------------------------------------------------------------------------------------->            
            
            <li><i  style="color: violet"
          ><font-awesome-icon
            icon="exclamation-circle"
            variant="warning"
            style="margin-right: 0.5rem"
          ></font-awesome-icon
          >{{ $t('Test Alert') }}</i
        ></li>
            {{ $t("Alerts triggered during test period. They are not taken into account in the statistics.") }}
          </ul>
      </p>
      
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
