<template>
  <div>
    <h1>{{ $t('help.ISASensorSetup_title') }}</h1>
    <p class="text-justify">
    {{ $t('The ISA sensor is still in setup phase. We are doing our best to finish the setup as soon as possible') }}
    <br/>
    </p>
    {{ $t( 'If you have any question regarding this sensor, contact your IT support or contact MintT technical support (support@mintt.care).') }}
  </div>
</template>

<script>

  export default  {
    components: {    
    },  
    data() {
      return {      
      }
    },
    watch : {    
    },    
    methods: {        
    },
    mounted() {            
    },
    name: "ISAHelpModalContent"
  }
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>

