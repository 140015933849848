<template>
  <b-modal
      hide-footer
      v-model="showChangePatternModal"
      @hidden="close"
      :title="$t('Change your lock pattern on this device')"
      size="lg"
      style="height: 800px"
      class=""
      >        

      <div class="d-flex justify-content-center">      
        <img :src="IMGService.isalogo" class="brand_logo" alt="Logo" style="margin-top: 20px"/>      
      <div class="justify-content-center form_container" >
        <form style="margin-top: 20px" autocomplete="off">
          <div class="user-thumb text-center m-b-30">
                  <img
                    :src="IMGService.isalock"
                    class="rounded-circle img-thumbnail"
                    alt="thumbnail"
                    style="height: 80px; padding-top:-10px"
                    v-b-toggle.collapseAdvanced
                  />
                  <div class="form-group text-center row m-t-20" style="margin-top: 50px">                    
                            <div class="row">
                                <div class="col-centered" >
                                      <p class="text-justify" style="font-weight: bold">{{text}}</p>
                                </div>
                          </div>                    
                  </div>
                </div>

                  <div class="input-group mb-3" v-if="!passwordChecked">
                     <input
                      type="text"
                      name
                      autocomplete="off" readonly 
                      @focus="removeReadonly"
                      class="form-control input_pass password"
                      value                      
                      v-model="password"
                      id="password"                      
                      @keypress="enterValidatePassword"
                    />
                  </div>
                  <div class="form-group text-center row m-t-20" v-if="!passwordChecked">                    
                    <b-button v-if="readyToSave" variant="primary" @click="validatePassword">{{ $t('Check my password')}} </b-button>                    
                  </div>

                  <div class="input-group mb-3" style="margin-top: 20px; width: 100%" v-if="!passwordChecked">
                        <b-button                          
                          tag="button"
                          class="btn-rounded btn-block mt-2"
                          variant="primary mt-2"
                          :disabled="!password"
                          @click="validatePassword"
                        >
                          {{$t('Check my password')}}
                        </b-button>                      
                    </div>

                  
                  <LockPattern @pattern="onPattern" ref='lp'
                      :cerror="patternError"
                      :csuccess="patternSuccess"
                      v-if="passwordChecked && !readyToSave && !hidePatten"
                  />

                  <div class="form-group text-center row m-t-20" v-if="passwordChecked">                    
                    <b-button v-if="readyToSave" variant="primary" @click="savePattern">{{ $t('Save my new pattern')}} </b-button>                    
                  </div>
        </form>
      </div>
    </div>        
    </b-modal>  
</template>

<script>
import { AuthService } from "@/services/auth.service";
import LockPattern from "@/components/ISA/session/LockPattern";
import { API_URL } from '../../../.env'
import { IMGService } from '@/services/img.service'

export default {
  name: "isa-loginform",
  props: {
    method: { type: Function }
  },
  components: {
    LockPattern
  },
  data() {
    return {
      IMGService,
      password: "",
      isLoading: false,
      firstPattern: false,
      firstPatternData: null,
      readyToSave: false,
      text: this.$store.state.user.currentUser.username + ', ' + this.$t("please enter your password first."),      
      passwordChecked: false,
      showChangePatternModal: false,
      patternSuccess: false,
      patternError: false,
      hidePatten: false,
    };
  },
  watch: {
    '$store.state.user.needsChangePattern': function(val) {
      this.showChangePatternModal = val;
      this.passwordChecked = false;
      this.firstPattern = false;
      this.readyToSave = false;
      this.text = this.$store.state.user.currentUser.username + ', ' + this.$t("please enter your password first.");
    }
  },
  mounted() {
    this.$store.commit('user/SET_NEEDS_CHANGEPATTERN', false)
    this.text = this.$store.state.user.currentUser.username + ', ' + this.$t("please enter your password first.");
    this.password = "";
    this.firstPattern = true;
    this.firstPatternData = null;
    this.patternSuccess = false;
    this.patternError = false;
    this.hidePatten = false;
  },


  methods: {
    removeReadonly(e) {
          e.target.removeAttribute('readonly');                
    },

    close() {           
        this.$store.commit('user/SET_NEEDS_CHANGEPATTERN', false);       
    },
    enterValidatePassword(e) {
      if (e.keyCode === 13) {
        this.validatePassword();
        e.preventDefault();
      }
    },
    async validatePassword() {
        // check authentication on openapi, if ok keep the password and starts pattern flow
        try
        {
          let res = await AuthService.makeLogin({ 
          apiUrl: API_URL.url, 
          username: this.$store.state.user.currentUser.username, 
          password: this.password, 
          organizationLabel: this.$store.state.fleet.currentOrganization });

        if(res && res.status == 200 && res.data) {
          this.passwordChecked = true;
          this.text = 'Enter your new pattern'
        }
        } catch(err) {
           this.$bvToast.toast( this.$t('Bad password entered'), {
            title: this.$t('Password Error'),            
            solid: true,
            variant: 'warning',
            duration: 5000
          });
        }
        
    },

    async onPattern(pattern) {
      console.log(pattern);
      if(!this.firstPattern) {
        this.firstPattern = true;        
        this.firstPatternData = pattern;
        console.log("FIRST PATTERN: " + pattern)
        this.patternSuccess = true
        this.text = this.$t("Please enter you pattern again, just to be sure")        
      } else
      {
        console.log("FIRST PATTERN: " + this.firstPatternData)
        console.log("SECOND PATTERN: " + pattern)
        if(pattern === this.firstPatternData)
        { // success, we can save this patter          
          this.patternSuccess = true;
          this.text = this.$t("This is correct, your lock pattern is ready.");
          this.readyToSave = true;
          this.readyToSaveData = pattern;
          /*
          window.setTimeout(function() {
              this.patternSuccess = false;
            }, 35000);
            */
          
            
        } // different pattern, let's restart
        else
        {
          this.patternError = true;
          this.firstPattern = false
          this.firstPatternData = null
          this.text = "Patterns does not match. Please try again."     
          this.hidePatten = true;
          setTimeout(() => {
              this.text = "Please define your new lock pattern."     
              this.patternError = false;                              
              this.hidePatten = false;
          }, 2000);
        }
        
        
      }
    },


/*
    login() {
      var vm = this;

      if (!this.path.length) return false;

      vm.success = false;
      vm.error = false;

      var pattern = vm.pathToString.toString();

      
      window["times_tried"] = window["times_tried"] + 1 || 0;
      this.isLoading = true;
      var vm = this;      
      if(!this.firstPattern) {
        this.firstPattern = true;        
        this.firstPatternData = pattern;
        console.log("FIRST PATTERN: " + pattern)
        vm.success = true
        this.text = "Please enter you pattern again, just to be sure"
        window.setTimeout(function() {            
            vm.success = false;
            vm.path = [];
          }, 1000);
      } else {
        console.log("FIRST PATTERN: " + this.firstPatternData)
        console.log("SECOND PATTERN: " + pattern)
        if(pattern === this.firstPatternData)
        { // success, we can save this patter          
          vm.success = true;
          this.text = "This is correct, your lock pattern is ready.\nPlease press save and next time, you can use your pattern to log in"
          this.readyToSave = true;
          this.readyToSaveData = pattern;
          window.setTimeout(function() {
              vm.success = false;
            }, 35000);
          
            
        } // different pattern, let's restart
        else
        {
          vm.error = true;
          this.firstPattern = false
          this.firstPatternData = null
          this.text = "Patterns does not match. Please try again."     
          setTimeout(() => {
            this.text = "Please define your lock pattern."     
              vm.error = false;
              vm.path = [];                       
          }, 2500);
        }
        
        
      }
    },
*/

    async savePattern() {
      await this.$store.dispatch('user/savePattern', { user: this.$store.state.user.currentUser, apiUrl: API_URL.url,  secret: this.readyToSaveData, password: this.password })            
      this.$bvToast.toast(this.$t('Your new pattern has been saved with success.'), {
              title: this.$t('Pattern reset'),            
              solid: true,
              variant: 'success',
              duration: 5000
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.isa-primary {
  background-color:#36958d !important;
}

.user_card {
  height: 400px;
  width: 100%;
  margin-top: 50px;
  margin-bottom: auto;
  background: white;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.brand_logo {
  position: absolute;
  text-align: center;
  height: 55px;
}
.form_container {
  margin-top: 100px;
}
.login_btn {
  width: 100%;
  background: #36958d !important;
  color: white !important;
}
.login_btn:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.login_container {
  padding: 0 2rem;
}
.input-group-text {
  background: #36958d !important;
  color: white !important;
  border: 0 !important;
  border-radius: 0.25rem 0 0 0.25rem !important;
}
.input_user,
.input_pass:focus {
  box-shadow: none !important;
  outline: 0px !important;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #c0392b !important;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #36958d;
  opacity: 0.6; /* Firefox */
}

.btn:focus {
  outline: none !important;
}

.btn-secondary {
  border-color: white;
}

.nav-pills {
    background-color: white;
}

.text-12 {
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.text-muted {
  font-weight: bold;
  color: rgb(87, 80, 80) !important;
}

.signinbtn {
  color:  rgb(0, 0, 0);
  font-weight: bold;
}

@media screen and (max-width: 640px) {
    .text-muted {
        color: rgb(129, 122, 122) !important;
    }
}

  .password {
      -webkit-text-security:disc;
      font-family: text-security-disc;
  }
</style>
