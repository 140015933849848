
<template>  
    <b-modal
      hide-footer
      v-model="showHelpModal"
      @hidden="close"
	  :size="$store.state.user.needsHelpTopic === 'ISAKioskHelp' ? 'lg' : 'xl'"
      :title="title">          
			<ISAAlertListDetectionHelp v-if="$store.state.user.needsHelpTopic === 'ISAAlertListDetectionHelp'" />
			<ISAAlertListNotificationHelp v-if="$store.state.user.needsHelpTopic === 'ISAAlertListNotificationHelp'" />
			<ISAAlertListVideoHelp v-if="$store.state.user.needsHelpTopic === 'ISAAlertListVideoHelp'" />
			<ISABedExitHelp v-if="$store.state.user.needsHelpTopic === 'ISABedExitHelp'" />
			<ISABedExitLicense v-if="$store.state.user.needsHelpTopic === 'ISABedExitLicense'" />
			<ISACloseFollowUpHelp v-if="$store.state.user.needsHelpTopic === 'ISACloseFollowUpHelp'" />
			<ISACloseFollowUpLicense v-if="$store.state.user.needsHelpTopic === 'ISACloseFollowUpLicense'" />
			<ISAInsightRoomFallRate v-if="$store.state.user.needsHelpTopic === 'ISAInsightRoomFallRate'" />
			<ISAInsightRoomTotalBedExit v-if="$store.state.user.needsHelpTopic === 'ISAInsightRoomTotalBedExit'" />
			<ISAInsightRoomTotalFalls v-if="$store.state.user.needsHelpTopic === 'ISAInsightRoomTotalFalls'" />
			<ISAKioskHelp v-if="$store.state.user.needsHelpTopic === 'ISAKioskHelp'" />
			<ISAMonthlyReportHelp v-if="$store.state.user.needsHelpTopic === 'ISAMonthlyReportHelp'" />
			<ISAMonthlyReportLicense v-if="$store.state.user.needsHelpTopic === 'ISAMonthlyReportLicense'" />
			<ISASensorEnabledHelp v-if="$store.state.user.needsHelpTopic === 'ISASensorEnabledHelp'" />
			<ISASensorOffline v-if="$store.state.user.needsHelpTopic === 'ISASensorOffline'" />
			<ISASensorSetup v-if="$store.state.user.needsHelpTopic === 'ISASensorSetup'" />
			<ISAStatalertingTime v-if="$store.state.user.needsHelpTopic === 'ISAStatalertingTime'" />
			<ISAStatbedExitAlertCount v-if="$store.state.user.needsHelpTopic === 'ISAStatbedExitAlertCount'" />
			<ISAStatcallsCount v-if="$store.state.user.needsHelpTopic === 'ISAStatcallsCount'" />
			<ISAStatcallsUnansweredCount v-if="$store.state.user.needsHelpTopic === 'ISAStatcallsUnansweredCount'" />
			<ISAStatfallAlertCount v-if="$store.state.user.needsHelpTopic === 'ISAStatfallAlertCount'" />
			<ISAStatfallEstimatedCount v-if="$store.state.user.needsHelpTopic === 'ISAStatfallEstimatedCount'" />
			<ISAStatfalseAlertsCount v-if="$store.state.user.needsHelpTopic === 'ISAStatfalseAlertsCount'" />
			<ISAStatreponseTime v-if="$store.state.user.needsHelpTopic === 'ISAStatreponseTime'" />
			<ISATimeZoneHelp v-if="$store.state.user.needsHelpTopic === 'ISATimeZoneHelp'" />
			<ISAUnlockPatternHelp v-if="$store.state.user.needsHelpTopic === 'ISAUnlockPatternHelp'" />
			<ISAWeeklyReportHelp v-if="$store.state.user.needsHelpTopic === 'ISAWeeklyReportHelp'" />
			<ISAWeeklyReportLicense v-if="$store.state.user.needsHelpTopic === 'ISAWeeklyReportLicense'" />
			<ISAOutOfLicenseBedexit v-if="$store.state.user.needsHelpTopic === 'ISAOutOfLicenseBedexit'" />
			<ISAOutOfLicenseFall v-if="$store.state.user.needsHelpTopic === 'ISAOutOfLicenseFall'" />

    </b-modal>  
</template>

<script>
import ISAAlertListDetectionHelp from './ISAAlertListDetectionHelp';
import ISAAlertListNotificationHelp from './ISAAlertListNotificationHelp';
import ISAAlertListVideoHelp from './ISAAlertListVideoHelp';
import ISABedExitHelp from './ISABedExitHelp';
import ISABedExitLicense from './ISABedExitLicense';
import ISACloseFollowUpHelp from './ISACloseFollowUpHelp';
import ISACloseFollowUpLicense from './ISACloseFollowUpLicense';
import ISAInsightRoomFallRate from './ISAInsightRoomFallRate';
import ISAInsightRoomTotalBedExit from './ISAInsightRoomTotalBedExit';
import ISAInsightRoomTotalFalls from './ISAInsightRoomTotalFalls';
import ISAKioskHelp from './ISAKioskHelp';
import ISAMonthlyReportHelp from './ISAMonthlyReportHelp';
import ISAMonthlyReportLicense from './ISAMonthlyReportLicense';
import ISASensorEnabledHelp from './ISASensorEnabledHelp';
import ISASensorOffline from './ISASensorOffline';
import ISASensorSetup from './ISASensorSetup';
import ISAStatalertingTime from './ISAStatalertingTime';
import ISAStatbedExitAlertCount from './ISAStatbedExitAlertCount';
import ISAStatcallsCount from './ISAStatcallsCount';
import ISAStatcallsUnansweredCount from './ISAStatcallsUnansweredCount';
import ISAStatfallAlertCount from './ISAStatfallAlertCount';
import ISAStatfallEstimatedCount from './ISAStatfallEstimatedCount';
import ISAStatfalseAlertsCount from './ISAStatfalseAlertsCount';
import ISAStatreponseTime from './ISAStatreponseTime';
import ISATimeZoneHelp from './ISATimeZoneHelp';
import ISAUnlockPatternHelp from './ISAUnlockPatternHelp';
import ISAWeeklyReportHelp from './ISAWeeklyReportHelp';
import ISAWeeklyReportLicense from './ISAWeeklyReportLicense';
import ISAOutOfLicenseBedexit from './ISAOutOfLicenseBedexit';
import ISAOutOfLicenseFall from './ISAOutOfLicenseFall';
import { SPService } from "@/services/sp.service";

export default  {
  components: {
		ISAAlertListDetectionHelp,
		ISAAlertListNotificationHelp,
		ISAAlertListVideoHelp,
		ISABedExitHelp,
		ISABedExitLicense,
		ISACloseFollowUpHelp,
		ISACloseFollowUpLicense,
		ISAInsightRoomFallRate,
		ISAInsightRoomTotalBedExit,
		ISAInsightRoomTotalFalls,
		ISAKioskHelp,
		ISAMonthlyReportHelp,
		ISAMonthlyReportLicense,
		ISASensorEnabledHelp,
		ISASensorOffline,
    ISASensorSetup,
		ISAStatalertingTime,
		ISAStatbedExitAlertCount,
		ISAStatcallsCount,
		ISAStatcallsUnansweredCount,
		ISAStatfallAlertCount,
		ISAStatfallEstimatedCount,
		ISAStatfalseAlertsCount,
		ISAStatreponseTime,
		ISATimeZoneHelp,
		ISAUnlockPatternHelp,
		ISAWeeklyReportHelp,
		ISAWeeklyReportLicense,
		ISAOutOfLicenseBedexit,
		ISAOutOfLicenseFall,

  },  
  data() {
    return {
      showHelpModal: false      
    }
  },
  computed: {
    title() {
			if(this.$store.state.user.needsHelpTopic === 'ISAAlertListDetectionHelp') return this.$t('help.ISAAlertListDetectionHelp');
			if(this.$store.state.user.needsHelpTopic === 'ISAAlertListNotificationHelp') return this.$t('help.ISAAlertListNotificationHelp');
			if(this.$store.state.user.needsHelpTopic === 'ISAAlertListVideoHelp') return this.$t('help.ISAAlertListVideoHelp');
			if(this.$store.state.user.needsHelpTopic === 'ISABedExitHelp') return this.$t('help.ISABedExitHelp');
			if(this.$store.state.user.needsHelpTopic === 'ISABedExitLicense') return this.$t('help.ISABedExitLicense');
			if(this.$store.state.user.needsHelpTopic === 'ISACloseFollowUpHelp') return this.$t('help.ISACloseFollowUpHelp');
			if(this.$store.state.user.needsHelpTopic === 'ISACloseFollowUpLicense') return this.$t('help.ISACloseFollowUpLicense');
			if(this.$store.state.user.needsHelpTopic === 'ISAInsightRoomFallRate') return this.$t('help.ISAInsightRoomFallRate');
			if(this.$store.state.user.needsHelpTopic === 'ISAInsightRoomTotalBedExit') return this.$t('help.ISAInsightRoomTotalBedExit');
			if(this.$store.state.user.needsHelpTopic === 'ISAInsightRoomTotalFalls') return this.$t('help.ISAInsightRoomTotalFalls');
			if(this.$store.state.user.needsHelpTopic === 'ISAKioskHelp') return this.$t('help.ISAKioskHelp');
			if(this.$store.state.user.needsHelpTopic === 'ISAMonthlyReportHelp') return this.$t('help.ISAMonthlyReportHelp');
			if(this.$store.state.user.needsHelpTopic === 'ISAMonthlyReportLicense') return this.$t('help.ISAMonthlyReportLicense');
			if(this.$store.state.user.needsHelpTopic === 'ISASensorEnabledHelp') return this.$t('help.ISASensorEnabledHelp');
			if(this.$store.state.user.needsHelpTopic === 'ISASensorOffline') return this.$t('help.ISASensorOffline');
			if(this.$store.state.user.needsHelpTopic === 'ISASensorSetup') return this.$t('help.ISASensorSetup');
			if(this.$store.state.user.needsHelpTopic === 'ISAStatalertingTime') return this.$t('help.ISAStatalertingTime');
			if(this.$store.state.user.needsHelpTopic === 'ISAStatbedExitAlertCount') return this.$t('help.ISAStatbedExitAlertCount');
			if(this.$store.state.user.needsHelpTopic === 'ISAStatcallsCount') return this.$t('help.ISAStatcallsCount');
			if(this.$store.state.user.needsHelpTopic === 'ISAStatcallsUnansweredCount') return this.$t('help.ISAStatcallsUnansweredCount');
			if(this.$store.state.user.needsHelpTopic === 'ISAStatfallAlertCount') return this.$t('help.ISAStatfallAlertCount');
			if(this.$store.state.user.needsHelpTopic === 'ISAStatfallEstimatedCount') return this.$t('help.ISAStatfallEstimatedCount');
			if(this.$store.state.user.needsHelpTopic === 'ISAStatfalseAlertsCount') return this.$t('help.ISAStatfalseAlertsCount');
			if(this.$store.state.user.needsHelpTopic === 'ISAStatreponseTime') return this.$t('help.ISAStatreponseTime');
			if(this.$store.state.user.needsHelpTopic === 'ISATimeZoneHelp') return this.$t('help.ISATimeZoneHelp');
			if(this.$store.state.user.needsHelpTopic === 'ISAUnlockPatternHelp') return this.$t('help.ISAUnlockPatternHelp');
			if(this.$store.state.user.needsHelpTopic === 'ISAWeeklyReportHelp') return this.$t('help.ISAWeeklyReportHelp');
			if(this.$store.state.user.needsHelpTopic === 'ISAWeeklyReportLicense') return this.$t('help.ISAWeeklyReportLicense');
			if(this.$store.state.user.needsHelpTopic === 'ISAOutOfLicenseBedexit') return this.$t('help.ISAOutOfLicenseBedexit');
			if(this.$store.state.user.needsHelpTopic === 'ISAOutOfLicenseFall') return this.$t('help.ISAOutOfLicenseFall');

        return this.$t('Unkown Help Page');
    }
  },
  watch : {
    '$store.state.user.needsHelp': function(val) {        
        this.showHelpModal = val     

		if(val) {
			const data = {
          fingerprint: this.$store.state.user.fingerprint,
          action: 'help',
          path:  this.$store.state.user.needsHelpTopic,
          data: window.location.href,
      }
        
       SPService.wbevent(this.$store.state.user.currentUser.apiUrl, this.$store.state.user.currentUser.jwt, data);
		}
    }
  },    
  methods: {
        close() {           
            this.$store.commit('user/NEEDS_HELP',null);                        
        }    
    },
  mounted() {      
      this.showHelpModal = this.$store.state.user.needsHelp && this.$route.path.indexOf('authenticate') < 0      
  },
  name: "ISAHelpModal"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>

