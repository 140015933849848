<template>
     <div>
      <h1>{{ $t('help.ISAStatfallAlertCount_title') }}</h1>
      <p class="text-justify">
          {{ $t('The count of triggered alerts') }}
          <br>
          <br>
          {{ $t('For each fall ISA can trigger multiple alerts if the patients is still on the floor after a couple of minutes. This is the number of alerts and not the number of falls.') }}          
      </p>
      
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
