<template>
     <div>
      <h1>{{ $t('help.ISAAlertListNotificationHelp_title') }}</h1>
      <p class="text-justify">
          {{ $t('This is the activity log following a detection. You can find here the details of each event and when they occured after a detection.') }}
      </p>
      
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
