<template>
     <div>
      <h1>{{ $t('help.ISAAlertListVideoHelp_title') }}</h1>
      <p class="text-justify">
          {{ $t('The video of the fall or bed exit.\n It is possible that more than one video are available for the same fall. This can occur because the sensor can trigger a new fall alert each minute while the situation is still dangerous.') }}
      </p>
      
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
