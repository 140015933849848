<template>
     <div>
      <h1>{{ $t('help.ISAStatcallsCount_title') }}</h1>
      <p class="text-justify">
          {{ $t('The total number of calls for the given period.') }}
          <br>
          <br>
          {{ $t('This number depends on several factors, such as:') }}
          <ul>
            <li>{{ $t('Number of phones: more you have phone configured, more you have calls because each of them will be called') }}</li>
            <li>{{ $t('Falls: more you have detected events, more you have calls') }}</li>
            <li>{{ $t('Bed Exits: more you have detected events, more you have calls') }}</li>
          </ul> 
      </p>
      
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
