<template>
     <div>
      <h1>{{ $t('help.ISAInsightRoomTotalFalls_title') }}</h1>
      <p class="text-justify">
          {{ $t('The number of the total (estimated) fall count.') }}
          <br>
          <br>
          {{ $t('For each fall, ISA can trigger multiple alerts. Each alerts are automatically regrouped by time and verified at a later time by MintT. ISA can\'t guarantuee that this is the exact number of falls, that\'s why the fall number is estimated.') }}
      </p>
      
    </div>
</template>

<script>

export default  {
  components: {    
  },  
  data() {
    return {      
    }
  },
  watch : {    
  },    
  methods: {        
    },
  mounted() {            
  },
  name: "ISAHelpModalContent"
}
</script>

<style lang="scss" scoped>
h1 {
  font-weight: 700;
}

.modal-title {
  font-weight: 700;
}

</style>
